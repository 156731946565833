const initialState = {
    peers:[],
    isUpdate: false,
    loader: true
  }
  
  const PeerListReducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_PEER_DATA":
        return {
            ...state,
            peers:[...action.data],
            totalPages: Math.ceil(action.peersCount / action.params),
            loader: action.loader
        }

        case "DELETE_PEER_ROW":
          return{
            ...state,
           peers: [...state.peers.filter(peer=>peer._id != action.data)],
           isUpdate: !state.isUpdate
          }
      
    
      case "ADD_PEER_DATA":
        return {
          ...state,
             peers:[...state.peers, action.data],
            isUpdate: !state.isUpdate
        }
      case "UPDATE_PEER_DATA":
        let index = state.peers.findIndex(ind => ind._id === action.data._id)
        return {
          ...state,
           peers:[...state.peers.slice(0, index), action.data, ...state.peers.slice(index+1)],
           isUpdate: !state.isUpdate
        }
      default:
        return state
    }
  }
  
  export default PeerListReducer
  