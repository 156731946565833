import axios from "axios";
import Axios from '../../../configs/axios.config' ;

import { toast } from "react-toastify"
import config from "../../../configs/properties";


export const fetchAllProjects = (params) => {
   const payload = {
    company:params.company,
    level:params.level,
    user:params.user
   }
    return async dispatch => {
     const allProjectsResult = await Axios.post("v2/project/fetchAllProjects", payload)
     if(allProjectsResult.data.statusCode === 200)
     {
     dispatch({
      type: "PROJECT_LIST",
      data: allProjectsResult.data.result
      })
     }
    }
  }

export const fetchAllProjectsClear = () => {
  localStorage.removeItem("company");
  localStorage.removeItem("username");
  localStorage.removeItem("adminaccess");
  localStorage.removeItem("userProfilePic");
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  return async dispatch => {
    dispatch({
     type: "PROJECT_LIST-CLEAR",
     data: []
     })
    }
}