const initialState = {
    result: [],
    dashBoardPeerList:[],
    loader:false
}
const ReadytoonboardListReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_READY_TO_ONBOARD":
            return {
                result: [...action.data],
                totalPages:(action.totalUsers % 10)===0 ? Math.round(action.totalUsers / 10):Math.round(action.totalUsers / 10)+1,
                totalUsers: action.totalUsers,
                loader:action.loader
            }
        case "GET_READY_TO_ONBOARD_IN_DASHBOARD":
            return {
                dashBoardPeerList:action.peerlist,
                loader:action.loader
            }
        default:
            return state
    }

}

export default ReadytoonboardListReducer