if (process.env.REACT_APP_STAGE === "build for dev") {
  module.exports = {
    base_url: "http://65.0.68.86/",
  };
} else if (process.env.REACT_APP_STAGE === "build for QA") {
  module.exports = {
    base_url: "http://65.0.68.86/",
  };
} else if (process.env.REACT_APP_STAGE === "build for Prod") {
  module.exports = {
    base_url: "https://api.timelyapp.io/",
  };
} else {
  module.exports = {
    base_url: "https://api.timelyapp.io/",
  };
}
// https://timely-api.codewave.com/ is deprecated