import axios from 'axios';
import config from './properties';


const instance = axios.create({
  baseURL: config.base_url,
  timeout: 0,
});
instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    if (error.response.status === 408) {
      localStorage.clear();
      window.location.href = "/"

    }else{
      localStorage.clear();
      window.location.href = "/"
    }
    return Promise.reject(error);
  }
);

export default instance;