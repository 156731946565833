const initialState = {
    feeds:[],
    isUpdate: false,
    pageCount:null,
    totalRecords:null
}

const feedListReducer =(state= initialState , action)=>{
   switch(action.type){
    case 'GET_FEED_DATA' :
        return{
            ...state,
            feeds:[...action.data.data],
            totalRecords:action.feedsCount,
            pageCount:Math.ceil(action.feedsCount / 10)
        }
    case 'CREATE_FEEDS' :
        return{
            ...state,
            isUpdate:!state.isUpdate
        }
    case 'UPDATE_FEED':{
        return {
            ...state,
            isUpdate:!state.isUpdate
        }
    }
    case 'DELETE_FEED':{
        return{
            ...state,
            isUpdate: !state.isUpdate
          }
    }
    default:
        return state;
   }
}
export default feedListReducer;