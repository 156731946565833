const initialState = {
    peersDetails: []
}

const PeerListDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_PEER_DETAILS":
            return {
                peersDetails: [...action.data]
            }
        default:
            return state
    }
}

export default PeerListDetailsReducer;
